import React from 'react'
import { Container, Row, Col } from 'react-awesome-styled-grid'
import styled from 'styled-components'
import siteConfig from '../../data/siteConfig'
import ResWrapper from '../components/reswrapper'
import SEO from '../components/SEO'
import loadable from '@loadable/component'
import pdfFile from '../assets/corey-waldrops-resume.pdf'

const Layout = loadable(() => import('../components/layout'))


const Image = styled.div`
	position: relative;
	
	@media (max-width 514px) {
		margin: 0;
	}
`

const Download = styled.div`
	position: relative;
	display: flex;
	align-items: center;
	margin: 50px auto 0;
	a {
		text-decoration: none;
		color: ${({ theme }) => theme.colors.fontColor};
	}
	a:hover {
		text-decoration: underline;
	}
	a:visited {
		color: ${({ theme }) => theme.colors.fontColor};
	}
`

const Resume = ({ className, location }) => {
	const title = "Resume"
	const { keywords, resumeFile } = siteConfig

	return (
    <Layout location={location}>
      <SEO
        title={title}
        keywords={keywords}
      />
			<ResWrapper>
				<Container className="page-content" fluid>
					<Row>
						<Col xs={8} className='resume-page'>
							<Download>
								<a href={pdfFile} download="corey-waldrop-resume.pdf">
									<h3>Download</h3>
								</a>
							</Download>
							<Image>
								{resumeFile.map(img => (
									<img 
										src={img.file}
										alt= "corey's resume"
									/>
								))}
							</Image>
						</Col>
					</Row>
				</Container>
			</ResWrapper>
		</Layout>
	)
}

export default styled(Resume)`
  .page-content {
    max-width: 100%;
    margin-bottom: 40px;
  }
	.resume-page {
		align-items: center;
		flex-direction: column;
	}
`
